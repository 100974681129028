const NOT_FOUND_RESPONSE_CODE = 404;

const urlIsLocalhost = !!(
  window.location.hostname === "localhost"
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === "[::1]"
    // 127.0.0.0/8 are considered localhost for IPv4.
    || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const registerValidServiceWorker = (serviceWorkerUrl, config) => {
  navigator.serviceWorker
    .register(serviceWorkerUrl)
    .then((registration) => {
      registration.addEventListener("updatefound", () => {
        const installingWorker = registration.installing;

        if (!installingWorker) return;
        installingWorker.addEventListener("statechange", () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              if (config && config.onUpdate) config.onUpdate(registration);
            } else if (config && config.onSuccess) config.onSuccess(registration);
          }
        });
      });
    })
    .catch(() => {});
};

const checkValidServiceWorker = (serviceWorkerUrl, config) => {
  fetch(serviceWorkerUrl, { headers: { "Service-Worker": "script" } })
    .then((response) => {
      const contentType = response.headers.get("content-type");

      if (response.status === NOT_FOUND_RESPONSE_CODE || (contentType && !contentType.includes("javascript"))) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => { window.location.reload(); });
        });
      } else registerValidServiceWorker(serviceWorkerUrl, config);
    })
    .catch(() => {});
};

export const register = (config) => {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) return;
    window.addEventListener("load", () => {
      const serviceWorkerUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (urlIsLocalhost) checkValidServiceWorker(serviceWorkerUrl, config);
      else registerValidServiceWorker(serviceWorkerUrl, config);
    });
  }
};

export const unregister = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch(() => {});
  }
};
