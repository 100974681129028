// TODO: Rewrite project to TS (add compiler, types, eslint rules)
// TODO: Use destructurization for actions type constants in all action creators
// TODO: Make data sorting on api side everywhere except it needs explicitly client sort

import "react-image-lightbox/style.css";

import "bootstrap/dist/css/bootstrap.min.css";

import "shards-ui/dist/css/shards.min.css";

import "react-table-6/react-table.css";

import "intro.js/introjs.css";

import "react-toastify/dist/ReactToastify.css";

import "index.scss";

import "polyfills";

import "core-js/features/promise/all-settled";

import * as Sentry from "@sentry/browser";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import { injectSpeedInsights } from "@vercel/speed-insights";
import { inject as injectVercelAnalytics } from "@vercel/analytics";
import Utils from "utils/Utils";

(async function() {
  const rootElement = window.document.getElementById("root");

  if (!Utils.checkIsDevMode()) {
    try {
      const { SENTRY_DSN, ENV_TYPE } = Utils.getProcessEnvVars();

      Sentry.init({ dsn: SENTRY_DSN, environment: ENV_TYPE });
    } catch (error) {}
    try {
      injectVercelAnalytics();
    } catch (error) {}
    try {
      injectSpeedInsights();
    } catch (error) {}
  }

  const { default: Main } = await import("./Main");

  Main.initApp(rootElement);
})();

serviceWorkerRegistration.unregister();
