export default class Countries {
  static AU = "AU";

  static CA = "CA";

  static CZ = "CZ";

  static GB = "GB";

  static US = "US";

  static ZA = "ZA";
}
